document.addEventListener("DOMContentLoaded", function () {
    const firstLink = document.querySelector(".leistungen li:first-child a");
    if (firstLink) {
        firstLink.classList.add("active");
    }
    const links = document.querySelectorAll(".leistungen a");
    links.forEach(link => {
        link.addEventListener("click", function (event) {
            event.preventDefault();

            const trigger = this.parentElement.getAttribute("id");

            const targetDiv = document.querySelector(`.leistungsdesc div#${trigger}`);
            if (targetDiv) {
                targetDiv.style.display = "block";
                const siblings = Array.from(targetDiv.parentElement.children).filter(
                    sibling => sibling !== targetDiv
                );
                siblings.forEach(sibling => sibling.style.display = "none");
            }

            links.forEach(link => link.classList.remove("active"));
            this.classList.add("active");
        });
    });

    function checkWidth() {
        const windowSize = window.innerWidth;
        const sideContent = document.querySelector(".side_content");
        const footer = document.querySelector(".footer");
        const wrapper = document.querySelector(".wrapper");

        if (windowSize <= 1164) {
            if (sideContent && footer) {
                footer.parentNode.insertBefore(sideContent, footer);
            }
        } else {
            if (sideContent && wrapper) {
                wrapper.appendChild(sideContent);
            }
        }
    }

    checkWidth();
    window.addEventListener("resize", checkWidth);

    const sideContent = document.querySelector(".side_content");
    if (sideContent && sideContent.children.length <= 0) {
        sideContent.remove();
    }
});